<template>
	<div>
		<h6>Pasarela de pagos - Culqi</h6>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row>
				<v-col cols="12" xl="6" lg="6" md="6" sm="6">
					<div class="form-group" :class="{ 'error--text': errors.public_token }">
						<label class="control-label">Token Público</label>
						<el-input v-model="form.public_token"></el-input>
						<small
							class="error--text"
							v-if="errors.public_token"
							v-text="errors.public_token[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="6" lg="6" md="6" sm="6">
					<div class="form-group" :class="{ 'error--text': errors.private_token }">
						<label class="control-label">Token Privado</label>
						<el-input v-model="form.private_token"></el-input>
						<small
							class="error--text"
							v-if="errors.private_token"
							v-text="errors.private_token[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="primary" native-type="submit" :loading="loadingSubmit">
						<template v-if="loadingSubmit">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loadingSubmit: false,
			resource: 'culqi-tokens',
			errors: {},
			form: {},
		};
	},
	async created() {
		await this.initForm();
		await this.getRecord();
	},
	methods: {
		getRecord() {
			this.$http.get(`/${this.resource}/record`).then((response) => {
				if (response.data.data) {
					this.form = response.data.data;
				}
			});
		},
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				public_token: null,
				private_token: null,
			};
		},
		submit() {
			this.errors = {};
			this.loadingSubmit = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.message;
					} else {
						console.log(error);
					}
				})
				.then(() => {
					this.loadingSubmit = false;
				});
		},
	},
};
</script>
