<template>
	<div>
		<h5>Ajustes</h5>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row dense>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<label>Envío automático de comprobantes</label>
					<div class="form-group" :class="{ 'error--text': errors.send_auto }">
						<el-switch
							v-model="form.send_auto"
							active-text="Si"
							inactive-text="No"
						></el-switch>
						<small
							class="error--text"
							v-if="errors.send_auto"
							v-text="errors.send_auto[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<label>Envío automático de resúmenes</label>
					<div class="form-group" :class="{ 'error--text': errors.cron }">
						<el-switch
							v-model="form.cron"
							active-text="Si"
							inactive-text="No"
						></el-switch>
						<small
							class="error--text"
							v-if="errors.cron"
							v-text="errors.cron[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<label>Venta con restricción de stock</label>
					<div class="form-group" :class="{ 'error--text': errors.stock_control }">
						<el-switch
							v-model="form.stock_control"
							active-text="Si"
							inactive-text="No"
						></el-switch>
						<small
							class="error--text"
							v-if="errors.stock_control"
							v-text="errors.stock_control[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-divider class="my-4"></v-divider>
			<v-row dense>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<label>Apertura de caja automático</label>
					<div class="form-group" :class="{ 'error--text': errors.auto_open_cash }">
						<el-switch
							v-model="form.auto_open_cash"
							active-text="Si"
							inactive-text="No"
						></el-switch>
						<small
							class="error--text"
							v-if="errors.auto_open_cash"
							v-text="errors.auto_open_cash[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<label>Cierre de caja automático</label>
					<div class="form-group" :class="{ 'error--text': errors.auto_close_cash }">
						<el-switch
							v-model="form.auto_close_cash"
							active-text="Si"
							inactive-text="No"
						></el-switch>
						<small
							class="error--text"
							v-if="errors.auto_close_cash"
							v-text="errors.auto_close_cash[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<label>Volver a crear comprobantes</label>
					<div class="form-group" :class="{ 'error--text': errors.recreate_document }">
						<el-switch
							v-model="form.recreate_document"
							active-text="Si"
							inactive-text="No"
						></el-switch>
						<small
							class="error--text"
							v-if="errors.recreate_document"
							v-text="errors.recreate_document[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-divider class="my-4"></v-divider>
			<v-row dense>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<label>Monto Mínimo delivery</label>
					<div class="form-group" :class="{ 'error--text': errors.minimum_amount }">
						<el-input-number
							v-model="form.minimum_amount"
							controls-position="right"
							:precision="2"
						></el-input-number>
						<small
							class="error--text"
							v-if="errors.minimum_amount"
							v-text="errors.minimum_amount[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<label>Tiempo de entrega aprox. (min)</label>
					<div class="form-group" :class="{ 'error--text': errors.time_of_Delivery }">
						<el-input-number
							v-model="form.time_of_Delivery"
							controls-position="right"
							:precision="0"
						></el-input-number>
						<small
							class="error--text"
							v-if="errors.time_of_Delivery"
							v-text="errors.time_of_Delivery[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="4">
					<div class="form-group" :class="{ 'error--text': errors.print_mode }">
						<label>Modo de impresión</label>
						<el-select v-model="form.print_mode">
							<el-option
								v-for="(option, index) in printModes"
								:key="index"
								:value="option.value"
								:label="option.name"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.print_mode"
							v-text="errors.print_mode[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-divider class="my-4"></v-divider>
			<v-row dense v-if="form.print_mode == 'direct'">
				<v-col cols="12" xl="4" lg="4" md="4" sm="6">
					<div class="form-group" :class="{ 'error--text': errors.connection_type }">
						<label>Tipo de conexión de impresora</label>
						<el-select v-model="form.connection_type">
							<el-option
								v-for="(option, index) in connectionTypes"
								:key="index"
								:value="option.value"
								:label="option.name"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.connection_type"
							v-text="errors.connection_type[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="6">
					<div class="form-group" :class="{ 'error--text': errors.pc_ip }">
						<label>IP de PC principal</label>
						<el-input v-model="form.pc_ip"></el-input>
						<small
							class="error--text"
							v-if="errors.pc_ip"
							v-text="errors.pc_ip[0]"
						></small>
					</div>
				</v-col>
				<v-col
					cols="12"
					xl="4"
					lg="4"
					md="4"
					sm="6"
					v-if="form.connection_type == 'network_print'"
				>
					<div class="form-group" :class="{ 'error--text': errors.printer_ip }">
						<label>IP de impresora</label>
						<el-input v-model="form.printer_ip"></el-input>
						<small
							class="error--text"
							v-if="errors.printer_ip"
							v-text="errors.printer_ip[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="6" v-else>
					<div class="form-group" :class="{ 'error--text': errors.printer_name }">
						<label>Nombre de impresora</label>
						<el-input v-model="form.printer_name"></el-input>
						<small
							class="error--text"
							v-if="errors.printer_name"
							v-text="errors.printer_name[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="4" lg="4" md="6">
					<div class="form-group" :class="{ 'error--text': errors.print_of_format }">
						<label>Formato de impresión</label>
						<el-select v-model="form.print_of_format">
							<el-option
								v-for="(option, index) in printOfFormats"
								:key="index"
								:value="option.value"
								:label="option.name"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.print_of_format"
							v-text="errors.print_of_format[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="primary" native-type="submit" :loading="loading_submit">
						<template v-if="loading_submit">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</div>
</template>

<script>
export default {
	// props:['typeUser'],

	data() {
		return {
			loading_submit: false,
			resource: 'configurations',
			errors: {},
			form: {},
			printModes: [
				{ value: 'dialog_box', name: 'Con cuadro de diálogo' },
				{ value: 'direct', name: 'Directa' },
			],
			printOfFormats: [
				{ value: 'a4', name: 'Tamaño A4' },
				{ value: 'a5', name: 'Tamaño A5' },
				{ value: 'ticket', name: 'Tamaño Ticket' },
			],
			connectionTypes: [
				{ value: 'windows_print', name: 'Impresora local' },
				{ value: 'network_print', name: 'Impresora en red' },
			],
		};
	},
	async created() {
		await this.initForm();
		await this.getRecord();
	},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				send_auto: true,
				stock_control: true,
				cron: true,
				discount_global: false,
				recreate_document: false,
				auto_open_cash: false,
				auto_close_cash: false,
				minimum_amount: 0,
				time_of_Delivery: 30,
				print_mode: 'direct',
				print_of_format: 'ticket',
				connection_type: 'windows_print',
				pc_ip: null,
				printer_ip: null,
				printer_name: null,
			};
		},
		getRecord() {
			this.$http.get(`/${this.resource}/record`).then((response) => {
				if (response.data !== '') this.form = response.data.data;
			});
		},
		submit() {
			this.errors = {};
			this.loading_submit = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading_submit = false;
				});
		},
	},
};
</script>
