<template>
	<div>
		<h6>Horario de atención Delivery</h6>
		<div class="table-responsive">
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th>DÍA</th>
							<th>ACTIVO</th>
							<th>HORA INICIO</th>
							<th>HORA FIN</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in form.records" :key="index">
							<td>{{ row.day }}</td>
							<td>
								<el-tooltip
									class="item"
									effect="dark"
									content="Activar/Desactivar"
									placement="top"
								>
									<el-switch
										v-model="row.active"
										inactive-color="#ff4949"
										@change="submit(row)"
									></el-switch>
								</el-tooltip>
							</td>
							<td>
								<el-time-select
									v-model="row.start_time"
									format="HH:mm"
									:disabled="!row.active"
									placeholder="Seleccionar"
									:picker-options="pickerOptions"
									@change="submit(row)"
									:clearable="false"
								></el-time-select>
							</td>
							<td>
								<el-time-select
									v-model="row.end_time"
									format="HH:mm"
									:disabled="!row.active"
									placeholder="Seleccionar"
									:picker-options="pickerOptions"
									@change="submit(row)"
									:clearable="false"
								></el-time-select>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			resource: 'office-hours',
			loading: false,
			form: {
				records: [],
			},
			errors: {},
			pickerOptions: {
				start: '08:00',
				step: '00:15',
				end: '23:45',
			},
		};
	},
	async created() {
		await this.getRecords();

		this.$eventHub.$on('reloadData', (resource) => {
			if (this.resource == resource) {
				this.getRecords();
			}
		});
	},
	methods: {
		getRecords() {
			return this.$http.get(`/${this.resource}/records`).then((response) => {
				this.form.records = response.data.data;
			});
		},
		submit(row) {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, row)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.message;
					} else {
						console.log(error);
					}
					this.loading = false;
				})
				.then(() => {
					this.loading = false;
				});
		},
	},
};
</script>
