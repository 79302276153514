<template>
	<div>
		<h6>Certificado digital</h6>
		<div class="table-responsive" v-if="record.certificate">
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th>Archivo</th>
							<th>Válido desde</th>
							<th>Valido hasta</th>
							<th class="text-right">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{{ record.certificate }}</td>
							<td>{{ record.certificate_date_of_issue }}</td>
							<td>{{ record.certificate_date_of_due }}</td>
							<td class="text-right">
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn
											color="accent"
											fab
											x-small
											dark
											@click.prevent="clickDelete()"
											v-on="on"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</template>
									<span>Eliminar</span>
								</v-tooltip>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<v-row v-else>
			<v-col cols="12" xl="12" class="text-center">
				<el-button type="primary" icon="el-icon-plus" @click="clickCreate">Subir</el-button>
			</v-col>
		</v-row>
		<certificates-form :showDialog.sync="showDialog" :recordId="recordId"></certificates-form>
	</div>
</template>

<script>
import CertificatesForm from './Form';
import { deletable } from 'Mixins/deletable';

export default {
	data() {
		return {
			showDialog: false,
			resource: 'certificates',
			recordId: null,
			record: {},
		};
	},
	mixins: [deletable],
	components: {
		CertificatesForm,
	},
	created() {
		this.$eventHub.$on('reloadData', (resource) => {
			if (this.resource == resource) {
				this.getData();
			}
		});
		this.getData();
	},
	methods: {
		getData() {
			this.$http.get(`/${this.resource}/record`).then((response) => {
				this.record = response.data;
			});
		},
		clickCreate() {
			this.showDialog = true;
		},
		clickDelete() {
			this.destroy(`/${this.resource}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
