<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<!-- <h5 class="mb-2">Configuraciones</h5> -->
			</div>
			<div class="action-btn-wrap"></div>
		</div>

		<v-row>
			<v-col cols="12" xl="10" lg="12" md="12" sm="12" offset-xl="1">
				<v-card color="secondary" class="pa-3">
					<emb-company></emb-company>
				</v-card>
			</v-col>
			<v-col cols="12" xl="10" lg="12" md="12" sm="12" offset-xl="1">
				<v-card color="secondary" class="pa-3">
					<emb-certificate></emb-certificate>
				</v-card>
			</v-col>
			<v-col cols="12" xl="10" lg="12" md="12" sm="12" offset-xl="1">
				<v-card color="secondary" class="pa-3">
					<emb-settings></emb-settings>
				</v-card>
			</v-col>
			<v-col cols="12" xl="10" lg="12" md="12" sm="12" offset-xl="1">
				<v-card color="secondary" class="pa-3">
					<emb-office-hour></emb-office-hour>
				</v-card>
			</v-col>
			<v-col cols="12" xl="10" lg="12" md="12" sm="12" offset-xl="1">
				<v-card color="secondary" class="pa-3">
					<emb-culqi-tokens></emb-culqi-tokens>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Company from 'Views/Admin/Company/Index';
import Settings from 'Views/Admin/Settings/Index.vue';
import CulqiTokens from 'Views/Admin/CulqiTokens/Index';
import Certificate from 'Views/Admin/Company/Component/Certificate/Index';
import OfficeHour from 'Views/Admin/OfficeHours/Index';

export default {
	data() {
		return {};
	},
	components: {
		embCompany: Company,
		embSettings: Settings,
		embCulqiTokens: CulqiTokens,
		embCertificate: Certificate,
		embOfficeHour: OfficeHour,
	},
	methods: {},
};
</script>
